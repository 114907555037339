"use strict";
let navBtnShow = document.querySelector(".c-header__nav-btn");
let menu = document.querySelector(".c-header__nav");
let navBtnClose = document.querySelector(".c-nav__close-btn");
let bodyOverlay = document.querySelector(".c-header__overlay");
let bodyLock = document.querySelector("body");

navBtnShow.addEventListener("click", function () {
  menu.classList.add("is-active");
  bodyOverlay.classList.add("is-active");
  bodyLock.classList.add("is-locked");
});

navBtnClose.addEventListener("click", function () {
  menu.classList.remove("is-active");
  bodyOverlay.classList.remove("is-active");
  bodyLock.classList.remove("is-locked");
});

bodyOverlay.addEventListener("click", function () {
  menu.classList.remove("is-active");
  bodyOverlay.classList.remove("is-active");
  bodyLock.classList.remove("is-locked");
});

let currentLang = document.querySelector(".c-footer__current-lang");
let langList = document.querySelector(".c-footer__lang-list");
let arrowLang = document.querySelector(".c-footer__icon-arrow");

currentLang.addEventListener("click", function () {
  currentLang.classList.toggle("is-active-switcher");
  langList.classList.toggle("is-active-switcher");
  arrowLang.classList.toggle("is-active-switcher");
});
